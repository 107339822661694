import React from "react";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
	mainBody: {
		width: "100%",
		height: "100vh",

		background: theme.palette.colors.secondary,
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
	},

	likha: {
		color: "white",
		fontWeight: "300",

		[theme.breakpoints.down("sm")]: {
			fontSize: "20px",
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: "30px",
		},
		[theme.breakpoints.up("md")]: {
			fontSize: "40px",
		},
		[theme.breakpoints.up("lg")]: {
			fontSize: "50px",
		},
	},
});

const NotFound = (props) => {
	const {classes} = props;

	return (
		<div className={classes.mainBody}>
			<React.Fragment>
				<span className={classes.likha}>404</span>
				<span className={classes.likha} style={{marginLeft: "7px", marginRight: "7px"}}>
					|
				</span>
				<span className={classes.likha}>Not Found</span>
			</React.Fragment>
		</div>
	);
};

NotFound.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
