import React from "react";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const styles = (theme) => ({
	outer: {
		fontSize: "25px",
		fontWeight: "500",
		color: "white",
		background: theme.palette.colors.secondary,

		width: "100%",
		height: "100vh",

		display: "flex",
		flexDirection: "center",
		justifyContent: "center",
		alignContent: "center",
		alignItems: "center",
		textAlign: "center",
	},
});

const ErrorLayout = (props) => {
	const {classes} = props;
	return <div className={classes.outer}>Something Went Wrong :(</div>;
};

ErrorLayout.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorLayout);
