import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";

import ErrorBoundary from "../util/ErrorBoundary";
import AOS from "aos";
import "aos/dist/aos.css";
import WindLoading from "../components/Loading/windLoading";
import NotFound from "../views/404/notFound";

import config from "../util/config";

const Home = lazy(() => {
	return Promise.all([
		import("../views/Home/home"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Directors = lazy(() => {
	return Promise.all([
		import("../views/Directors/directors"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Research = lazy(() => {
	return Promise.all([
		import("../views/Research/research"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Consultation = lazy(() => {
	return Promise.all([
		import("../views/Consultation/consultation"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Plans = lazy(() => {
	return Promise.all([
		import("../views/Plans/plans"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Events = lazy(() => {
	return Promise.all([
		import("../views/Events/events"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Gallery = lazy(() => {
	return Promise.all([
		import("../views/Gallery/gallery"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Contact = lazy(() => {
	return Promise.all([
		import("../views/Contact/contact"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Personnel = lazy(() => {
	return Promise.all([
		import("../views/Contact/Personnel"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Notice = lazy(() => {
	return Promise.all([
		import("../views/Notice/notice"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const Webinar = lazy(() => {
	return Promise.all([
		import("../views/Webinar/webinar"),
		new Promise((resolve) => setTimeout(resolve, config.FallbackDelay)),
	]).then(([module]) => module);
});

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#ac1f25",
		},

		colors: {
			primary: "#808080",
			secondary: "#ac1f25",
			ternary: "rgba(233, 230, 223, 0.4);",
		},
	},

	typography: {},
});

AOS.init();
const BaseLayout = () => (
	<Router>
		<Suspense fallback={<WindLoading />}>
			<MuiThemeProvider theme={theme}>
				<ErrorBoundary>
					<div>
						<Switch>
							<Route exact path="/" component={Home} />
							<Route exact path="/directors" component={Directors} />
							<Route exact path="/personnel" component={Personnel} />
							<Route exact path="/research" component={Research} />
							<Route exact path="/consultation" component={Consultation} />
							<Route exact path="/plans" component={Plans} />
							<Route exact path="/events" component={Events} />
							<Route exact path="/gallery/:id" component={Gallery} />
							<Route exact path="/contact" component={Contact} />
							<Route exact path="/notice" component={Notice} />
							<Route exact path="/webinar_09_01_2021" component={Webinar} />
							<Route exact path="*" component={NotFound} />
						</Switch>
					</div>
				</ErrorBoundary>
			</MuiThemeProvider>
		</Suspense>
	</Router>
);

export default BaseLayout;
