import React from "react";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import Windmill from "../Windmill/windmill";

const styles = (theme) => ({
	mainBody: {
		height: "100vh",
		width: "100%",
		overflow: "hidden",

		position: "relative",
	},

	container: {
		height: "300px",
		width: "250px",

		position: "absolute",
	},

	con1: {
		top: "calc((100% - 300px) / 2)",
		left: "calc((100% - 250px) / 2)",
	},

	con2: {
		transform: "scale(0.5, 0.5)",
		left: "calc((100% - 250px) / 2 - 10%)",
		top: "30%",
	},

	con3: {
		transform: "scale(0.5, 0.5)",
		left: "calc((100% - 250px) / 2 + 10%)",
		top: "30%",
	},

	prog: {
		color: "#ac1f25",
	},
});

const WindLoading = (props) => {
	const {classes} = props;
	return (
		<div className={classes.mainBody}>
			<div className={`${classes.container} ${classes.con1}`}>
				<Windmill />
			</div>
			<div className={`${classes.container} ${classes.con2}`}>
				<Windmill />
			</div>
			<div className={`${classes.container} ${classes.con3}`}>
				<Windmill />
			</div>
		</div>
	);
};

WindLoading.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WindLoading);
