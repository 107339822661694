import React from "react";
import "./windmill.css";

const Windmill = () => {
	return (
		<div className="container">
			<div className="fan">
				<div className="fan_blade_outer_1">
					<div className="fan_blade_down"></div>
					<div className="fan_blade_up"></div>
				</div>

				<div className="fan_blade_outer_2">
					<div className="fan_blade_down"></div>
					<div className="fan_blade_up"></div>
				</div>

				<div className="fan_blade_outer_3">
					<div className="fan_blade_down"></div>
					<div className="fan_blade_up"></div>
				</div>
			</div>
			<div className="mill"></div>
		</div>
	);
};

export default Windmill;
